@font-face {
  font-family: "Fonseca";
  src: url("/public/assets/fonts/Fonseca.otf") format("opentype");
}

@font-face {
  font-family: "Quicksand";
  src: url("/public/assets/fonts/Quicksand.ttf") format("truetype");
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
